import React from 'react'
import { Layout, UserContext, UserContextInterface } from '../../components/Layout'
import { EditForm } from '../../components/issues/EditForm'
import { createIssue, Issue } from '../../api/cms'
import { Modal } from '../../components/Modal'
import { getUserDetails, LoginResponse } from '../../api/user'
import { ParagraphLarge } from 'baseui/typography'
import { Button } from '../../components/Button'
import { useStyletron } from 'baseui'
import { loginRedirect } from '../../utils'

interface LayoutCreateIssueProps {
  location: {
    search: string
  }
}

function saveFunc(context: UserContextInterface, data: Issue, file: File, token: string | null) {
  if (token) {
    const success = (response: LoginResponse) => {
      if (context.setUserContext) {
        context.setUserContext(response, context.rememberMe || false)
      }
    }

    const createIssueSuccess = () => {
      if (!context.hasCreatedIssue) {
        return getUserDetails(token, success)
      }
    }
    return createIssue(data, file, token).then(createIssueSuccess)
  }
}

const CreateIssue = (props: LayoutCreateIssueProps) => {
  const { hasCreatedIssue } = React.useContext(UserContext)
  const [isOpen, setIsOpen] = React.useState(hasCreatedIssue !== null ? !hasCreatedIssue : false)
  const context = React.useContext(UserContext)
  const { token } = React.useContext(UserContext)

  const [css] = useStyletron()
  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
  }, [token])

  const footer = (
    <Button
      kind="secondary"
      data-testid="close-footer-button"
      onClick={() => setIsOpen(false)}
      overrides={{
        BaseButton: {
          style: {
            width: '33%',
            marginTop: '0px'
          }
        }
      }}
    >
      OK
    </Button>
  )

  const pMargin = { Block: { style: { marginBottom: '0.4rem' } } }
  return (
    <>
      <Modal
        headerText="Log your first issue"
        footer={footer}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <ParagraphLarge overrides={pMargin}>
          This log is a personal record of any OHS issues in your workplace.
        </ParagraphLarge>
        <ParagraphLarge marginBottom="19px"> You can use it to:</ParagraphLarge>
        <ul
          className={css({
            fontSize: '18px',
            marginBottom: '0.4rem'
          })}
        >
          <li>record and track OHS issues and incidents</li>
          <li>photograph workplace hazards </li>
          <li>add consultation notes </li>
          <li>email incidents to management or workmates </li>
        </ul>
        <ParagraphLarge overrides={pMargin}>
          You can enter issue details all at once or start an entry and come back later to finish
          it. Once logged, your issue will go into your list where you can resolve/update it when
          you need.
        </ParagraphLarge>
        <ParagraphLarge overrides={{ Block: { style: { marginBottom: '1rem' } } }}>
          Nb. This log does not replace any agreed reporting procedures in your workplace.
        </ParagraphLarge>
      </Modal>
      <EditForm saveFunc={saveFunc.bind(null, context)} query={props.location.search} />
    </>
  )
}

const LayoutCreateIssue = (props: LayoutCreateIssueProps) => {
  return (
    <Layout>
      <CreateIssue location={props.location} />
    </Layout>
  )
}

export default LayoutCreateIssue
